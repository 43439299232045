import H4 from 'primitives/Typography/Headings/H4';
import H5 from 'primitives/Typography/Headings/H5';
import RailItems from 'views/Podcast/primitives/RailItems';
import RailSection from 'views/Podcast/primitives/RailSection';
import Row from './Row';
import RowText from './primitives/RowText';
import RowThumb from './primitives/RowThumb';
import ShouldShow from 'components/ShouldShow';
import Truncate from 'components/Truncate';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { getPodcastHosts } from 'state/Podcast/selectors';
import { THUMB_RES } from 'components/MediaServerImage';
import { useSelector } from 'react-redux';

function HostsAndCreators() {
  const translate = useTranslate();
  const hosts = useSelector(getPodcastHosts);

  const hostsElements = hosts.map(({ image, link, title }) => (
    <Row key={title} to={link}>
      <RowThumb
        alt={title}
        aspectRatio={1}
        height={THUMB_RES}
        src={image}
        width={THUMB_RES}
      />
      <RowText data-test="row-text">
        <H5>
          <Truncate lines={1}>{title}</Truncate>
        </H5>
      </RowText>
    </Row>
  ));

  return (
    <ShouldShow shouldShow={hosts.length > 0}>
      <RailSection>
        <H4>
          {translate(['Host', 'Hosts And Creators', 'hosts'], {
            hosts: hosts.length,
          })}
        </H4>
        <RailItems>{hostsElements}</RailItems>
      </RailSection>
    </ShouldShow>
  );
}

export default HostsAndCreators;
