import CatalogImage from 'components/MediaServerImage/CatalogImage';
import styled from '@emotion/styled';

const RowThumb = styled(CatalogImage)({
  borderRadius: '50%',
  height: '6rem',
  minWidth: '6rem',
  overflow: 'hidden',
  width: '6rem',
});

export default RowThumb;
