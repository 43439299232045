import NavLink from 'components/NavLink';
import RowPrimitive from './primitives/Row';
import { ReactNode } from 'react';

type Props = { children: ReactNode; to?: string };

const RowLink = RowPrimitive.withComponent(NavLink);

function Row(props: Props) {
  if (props.to) {
    return <RowLink to={props.to}>{props.children}</RowLink>;
  }

  return <RowPrimitive>{props.children}</RowPrimitive>;
}

export default Row;
